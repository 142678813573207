"use client";

import { storyblokEditable } from "@storyblok/react";
import { Container } from "@wojo/ui";
import cn from "clsx";
import React from "react";
import { getSpacingClassName } from "../../client/get-spacing-class-name";
import { useGlobalMerchandisingCard } from "../../client/use-global-merchandising-card";
import { useWishlist } from "../../client/use-wishlist";
import { SchemaScript } from "../../components/schema-script";
import { SearchResults } from "../../components/search-results";
import { TextCardProps } from "../../molecules/text-card";
import { TourCardGridContent } from "./tour-card-grid-content/TourCardGridContent";
import styles from "./TourCardGrid.module.scss";
import { TourCardGridFallback } from "./TourCardGridFallback";
import { TourCardGridProps } from "./types";
import { NoResultsBanner } from "../../components/search-results/no-results-banner/NoResultsBanner";
import { useI18n } from "@wojo/localization";

const emptyMerchandisingCard: [TextCardProps] | [] = [];

export const TourCardGrid: React.FC<TourCardGridProps> = (props) => {
    const {
        _uid,
        algoliaRuleTriggerContext,
        hiddenFilters,
        numberOfTours,
        spaceAbove,
        spaceBelow,
        ...rest
    } = props;

    const { getIsInWishlist, toggleWishlist } = useWishlist();
    const tourCardGridI18n = useI18n("storyblok").storyblok.tourCardGrid;

    // Global merchandising card values
    const {
        hideMerchandisingCard: hideGlobalMerchandisingCard,
        merchandisingCard: globalMerchandisingCard,
        merchandisingCardPlacement: globalMerchandisingCardPlacement,
    } = useGlobalMerchandisingCard();

    // Local merchandising card values
    const {
        hideMerchandisingCard: hideAllMerchandisingCards = false,
        merchandisingCard: localMerchandisingCard = emptyMerchandisingCard,
        merchandisingCardPlacement: localMerchandisingCardPlacement = "1",
    } = rest;

    let merchandisingCard: [TextCardProps] | [] = emptyMerchandisingCard;
    if (!hideAllMerchandisingCards && localMerchandisingCard.length) {
        merchandisingCard = localMerchandisingCard;
    } else if (!hideAllMerchandisingCards && !hideGlobalMerchandisingCard) {
        merchandisingCard = globalMerchandisingCard;
    }

    const merchandisingCardPlacement =
        localMerchandisingCardPlacement ||
        globalMerchandisingCardPlacement ||
        "1";

    const filters = (
        [
            "destinations",
            "dates",
            "duration",
            "price",
            "activityLevel",
            "tripType",
        ] as const
    ).filter((filter) => !hiddenFilters?.includes(filter));

    const numberOfToursWithFallback = parseInt(numberOfTours || "18");
    const hitsPerPage = numberOfToursWithFallback - merchandisingCard.length;
    return (
        <Container
            {...storyblokEditable(props)}
            className={cn(
                styles["component-wrapper"],
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
            data-ga4-object="TOUR_GRID"
        >
            <SearchResults
                queryKey={["tour-card-grid", _uid]}
                queryType="tourPage"
                ruleContexts={
                    algoliaRuleTriggerContext ? [algoliaRuleTriggerContext] : []
                }
                loadingFallback={
                    <TourCardGridFallback
                        numberOfResults={hitsPerPage}
                        showDesktopCardOnMobile={
                            props.showDesktopCardOnMobile ?? false
                        }
                    />
                }
                hitLabel="tours"
                hitsPerPage={hitsPerPage}
                filters={filters}
                showSort={!hiddenFilters?.includes("sort")}
                render={({ currentPage, pages, hits, hasFiltersApplied }) => {
                    if (!hits.length) {
                        return (
                            <NoResultsBanner
                                hasFiltersApplied={hasFiltersApplied}
                                noResults={tourCardGridI18n.noResults}
                            />
                        );
                    }
                    return (
                        <>
                            <SchemaScript
                                data={{
                                    "@context": "https://schema.org",
                                    "@type": "ItemList",
                                    itemListElement: hits.map(
                                        (tour, index) => ({
                                            "@type": "ListItem",
                                            position: index + 1,
                                            item: {
                                                "@type": "Product",
                                                name: tour.name,
                                                url:
                                                    process.env
                                                        .NEXT_PUBLIC_DOMAIN +
                                                    tour.url,
                                                image: tour.cardImage?.filename,
                                                description:
                                                    tour.overviewDescription,
                                                sku: tour.tourCode,
                                                brand: {
                                                    "@type": "Brand",
                                                    name: process.env
                                                        .NEXT_PUBLIC_BUSINESS_NAME,
                                                },
                                                ...((tour.salePrice ||
                                                    tour.price) && {
                                                    offers: {
                                                        "@type": "Offer",
                                                        priceCurrency:
                                                            process.env
                                                                .NEXT_PUBLIC_CURRENCY_CODE,
                                                        price:
                                                            tour.salePrice ||
                                                            tour.price,
                                                        url:
                                                            process.env
                                                                .NEXT_PUBLIC_DOMAIN +
                                                            tour.url,
                                                        itemCondition:
                                                            "https://schema.org/NewCondition",
                                                        availability:
                                                            "http://schema.org/InStock",
                                                    },
                                                }),
                                                ...(tour.nbRatings > 0 && {
                                                    aggregateRating: {
                                                        "@type":
                                                            "AggregateRating",
                                                        ratingValue:
                                                            tour.rating,
                                                        reviewCount:
                                                            tour.nbRatings,
                                                    },
                                                }),
                                            },
                                        }),
                                    ),
                                }}
                            />

                            <TourCardGridContent
                                {...rest}
                                currentPage={currentPage}
                                getIsInWishlist={getIsInWishlist}
                                merchandisingCard={merchandisingCard}
                                merchandisingCardPlacement={
                                    merchandisingCardPlacement
                                }
                                pages={pages}
                                toggleWishlist={toggleWishlist}
                                tours={hits}
                            />
                        </>
                    );
                }}
            />
        </Container>
    );
};
